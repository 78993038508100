import { Injectable } from '@angular/core';
import { MsalService } from '@azure/msal-angular';

import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpHeaders
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { APIErrorHandler } from './api-error-handler.service';
import { AppConfigService, AppConfig } from '@app/app-config.service';

@Injectable()
export class APIInterceptor implements HttpInterceptor {
  private _config: AppConfig;

  constructor(
    private _appConfig: AppConfigService,
    public errorHandler: APIErrorHandler,
    private authService:MsalService
  ) {
    this._config = this._appConfig.getConfig();
  }
  async getToken():Promise<void>{
    const apiScope = this._config.msalConfig.geobrain_scope;
    try{
      const _account = this.authService.getAccount();
      const response = await this.authService.acquireTokenSilent({
        scopes:[apiScope],
        account: _account
      });
      localStorage.setItem("token",response.accessToken);
    }catch(error){
      const _account1 = this.authService.getAccount();
      const response1 = await this.authService.acquireTokenPopup({
        scopes:[apiScope],
        account: _account1
      });
      localStorage.setItem("token",response1.accessToken);
    }
  }
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const requestUrl = request.url;
    if (requestUrl.includes(this._config.datascience_api_baseurl) || requestUrl.includes(this._config.geoBertAPIUrl)) {
      this.getToken();
      var apiToken = localStorage.getItem("token");
      console.log(apiToken);
      if (apiToken == null) {
        request = request.clone({
          headers: new HttpHeaders({
            'Cache-Control': 'no-cache',
            'Pragma': 'no-cache'
          }),
          withCredentials: true
        });
      } else {
        request = request.clone({
          headers: new HttpHeaders({
            'Cache-Control': 'no-cache',
            'Pragma': 'no-cache'
          }),
          setHeaders: {
            'Authorization':'Bearer '+apiToken
            }
        });
      }
    }
    return next.handle(request);
  }
}
